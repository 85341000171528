import * as React from "react";
import { NavLink, Outlet } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import NotificationsIcon from "@mui/icons-material/Notifications";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import WorkIcon from "@mui/icons-material/Work";
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import Logo from "./../../Assets/Images/logo.png";
import DefaultUser from "./../../Assets/Images/default-user.png";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import "./LeftSideNav.css";
import MenuBookIcon from '@mui/icons-material/MenuBook';

// left navigation
const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// searchbar
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "30ch",
    },
  },
}));

export default function LeftSideNav() {
  // left navigation
  const [open, setOpen] = React.useState(false);

  const handleDrawerToggole = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    return () => {
      handleDrawerToggole();
    };
  }, []);

  // user profile
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id="menuId"
      open={isMenuOpen}
      onClose={handleMenuClose}
      onClick={handleMenuClose}
      MenuListProps={{ onMouseLeave: handleMenuClose }}
      className="dropdown"
      PaperProps={{
        elevation: 0,
        sx: {
          width: "180px",
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: -0.8,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem onClick={handleMenuClose}>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        Profile
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleMenuClose}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  );

  // notifications
  const [notifyEl, setNotifyEl] = React.useState(null);
  const isNotifyMenuOpen = Boolean(notifyEl);
  const notifyProfileMenuOpen = (event) => {
    setNotifyEl(event.currentTarget);
  };
  const notifyMenuClose = () => {
    setNotifyEl(null);
  };
  const renderNofiyMenu = (
    <Menu
      anchorEl={notifyEl}
      id="notifyMenuId"
      open={isNotifyMenuOpen}
      onClose={notifyMenuClose}
      onClick={notifyMenuClose}
      MenuListProps={{ onMouseLeave: notifyMenuClose }}
      className="dropdown"
      PaperProps={{
        elevation: 0,
        sx: {
          width: "180px",
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: -0.8,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <ListItem button>
        <ListItemAvatar>
          <Avatar>
            <ImageIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Photos" secondary="Jan 9, 2014" />
      </ListItem>
      <Divider />
      <ListItem button>
        <ListItemAvatar>
          <Avatar>
            <WorkIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Work" secondary="Jan 7, 2014" />
      </ListItem>
    </Menu>
  );

  // open submenu
  const [ShowTeacher, setShowTeacher] = React.useState(false);
  const [ShowClass, setShowClass] = React.useState(false);
  const [ShowScheduleClass, setShowScheduleClass] = React.useState(false);
  const [ShowStudent, setShowStudent] = React.useState(false);

  const handleClickTeacher = () => {
    setShowTeacher(!ShowTeacher);
  };
  const handleClickClass = () => {
    setShowClass(!ShowClass);
  };
  const handleClickScheduleClass = () => {
    setShowScheduleClass(!ShowScheduleClass);
  };
  const handleClickStudent = () => {
    setShowStudent(!ShowStudent);
  };

  const TeacherSubMenu = [
    { icon: <CheckBoxIcon />, SubMenuName: "All Teacher", Link: "/all-teacher" },
    { icon: <CheckBoxIcon />, SubMenuName: "Add User", Link: "/add-teacher" }
  ]
  const ScheduleClassSubMenu = [
    { icon: <CheckBoxIcon />, SubMenuName: "All Schedule Class", Link: "/all-schedule-class" },
    { icon: <CheckBoxIcon />, SubMenuName: "Schedule Class", Link: "/schedule-class" }
  ]
  const ClassSubMenu = [
    { icon: <CheckBoxIcon />, SubMenuName: "Book Class", Link: "/book-class" },
    { icon: <CheckBoxIcon />, SubMenuName: "Booked Classes", Link: "/booked-classes" }
  ]
  const StudentSubMenu = [
    { icon: <CheckBoxIcon />, SubMenuName: "All Student", Link: "/student" },
    { icon: <CheckBoxIcon />, SubMenuName: "Add Student", Link: "/add-student" }
  ]

  const SideMenu = [
    {
      icon: <DashboardIcon />,
      MenuName: "Dashboard",
      Link: "",
      submenu: "No",
      MapFunction: "",
      setFunction: "",
      onclickFunction: "",
    },
    {
      icon: <MenuBookIcon />,
      MenuName: "Schedule Class",
      Link: null,
      submenu: "Yes",
      MapFunction: ScheduleClassSubMenu,
      setFunction: ShowScheduleClass,
      onclickFunction: handleClickScheduleClass,
    },
    {
      icon: <AppRegistrationIcon />,
      MenuName: "My Class",
      Link: null,
      submenu: "Yes",
      MapFunction: ClassSubMenu,
      setFunction: ShowClass,
      onclickFunction: handleClickClass,
    },
    {
      icon: <SwitchAccountIcon />,
      MenuName: "Students",
      Link: null,
      submenu: "Yes",
      MapFunction: StudentSubMenu,
      setFunction: ShowStudent,
      onclickFunction: handleClickStudent,
    },
    {
      icon: <SupervisorAccountIcon />,
      MenuName: "Teacher",
      Link: null,
      submenu: "Yes",
      MapFunction: TeacherSubMenu,
      setFunction: ShowTeacher,
      onclickFunction: handleClickTeacher,
    },
    {
      icon: <AssessmentIcon />,
      MenuName: "Course",
      Link: "/course",
      submenu: "No",
      MapFunction: "",
      setFunction: "",
      onclickFunction: "",
    },
    {
      icon: <AppRegistrationIcon />,
      MenuName: "Instructors",
      Link: "/instructors",
      submenu: "No",
      MapFunction: "",
      setFunction: "",
      onclickFunction: "",
    },
    {
      icon: <ManageAccountsIcon />,
      MenuName: "Profile",
      Link: '/profile',
      submenu: "No",
      MapFunction: "",
      setFunction: "",
      onclickFunction: "",
    },

  ];

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <img src={Logo} alt="logo" style={{ width: "120px", marginRight: '2rem' }} />
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggole}
              edge="start"
            >
              <MenuIcon />
              {/* {open === false ?
              : <CloseIcon />} */}
            </IconButton>
            <Search className="searchbar">
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Tooltip title="Notifications">
                <IconButton
                  aria-label="show 1 new notifications"
                  color="inherit"
                  onClick={notifyProfileMenuOpen}
                  onMouseOver={notifyProfileMenuOpen}
                  size="large"
                  sx={{ ml: 2 }}
                  aria-controls={isNotifyMenuOpen ? "notifyMenuId" : undefined}
                  aria-haspopup="true"
                  aria-expanded={isNotifyMenuOpen ? "true" : undefined}
                >
                  <Badge badgeContent={1} color="error">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleProfileMenuOpen}
                  onMouseOver={handleProfileMenuOpen}
                  size="large"
                  color="inherit"
                  sx={{ ml: 2 }}
                  aria-controls={isMenuOpen ? "menuId" : undefined}
                  aria-haspopup="true"
                  aria-expanded={isMenuOpen ? "true" : undefined}
                >
                  <img src={DefaultUser} alt="user" className="userimg" />
                  <span className="title">User</span>
                  <KeyboardArrowDownIcon sx={{ fontSize: "18px", mt: "-2px" }} />
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMenu}
        {renderNofiyMenu}
        <Drawer variant="permanent" open={open} className="leftsidenav">
          <List>
            {SideMenu.map((Menu, id) => {
              return (
                <ListItem
                  key={id}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                    onClick={Menu.Link === null ? Menu.onclickFunction : null}
                    component={Menu.Link === null ? "div" : NavLink}
                    to={Menu.Link === null ? "" : Menu.Link}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 1 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {Menu.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={Menu.MenuName}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                    {open ? (
                      <>
                        {Menu.submenu === "Yes" ? (
                          <>
                            {Menu.setFunction ? <KeyboardArrowDownIcon /> : <ChevronLeftIcon />}
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </ListItemButton>
                  {
                    Menu.submenu === "Yes" ? (
                      <>
                        <Collapse timeout="auto" in={Menu.setFunction} unmountOnExit>
                          {Menu.MapFunction.map((row, subid) => {
                            return (
                              <div key={subid}>
                                <List component="div" disablePadding >
                                  <ListItemButton
                                    sx={{ pl: open ? 5 : 2.5 }}
                                    component={NavLink}
                                    to={row.Link}
                                  >
                                    <ListItemIcon
                                      sx={{
                                        minWidth: 0,
                                        mr: open ? 1 : "auto",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {row.icon}
                                    </ListItemIcon>
                                    {open ? (
                                      <ListItemText primary={row.SubMenuName} />
                                    ) : null}
                                  </ListItemButton>
                                </List>
                                <Divider />
                              </div>
                            );
                          })}
                        </Collapse>
                      </>
                    ) : null
                  }
                </ListItem>
              );
            })}
          </List>
        </Drawer >
        <Box component="main" className='main' sx={{ p: 3, pt: 10 }}>
          <Outlet />
        </Box>
      </Box >
    </>
  );
}

