import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import "./BackendCommon.css";

export const Description = (props) => {
    return <Typography className="description">{props.description}</Typography>;
};

export const Heading = (props) => {
    return (
        <Typography className="heading" sx={props.css}>
            {props.heading}
        </Typography>
    );
};

export const CustomInputField = (props) => {
    return (
        <input
            type={props.type}
            placeholder={props.placeholder}
            required={props.required}
            className="custom-input-filed"
            onChange={props.onChange}
            name={props.name}
            sx={props.inlinecss}
        />
    );
};
export const CustomFormGroup = (props) => {
    return (
        <>
            <Box className="custom-form-group">
                <Typography className="custom-form-label">{props.label}</Typography>
                <input
                    type={props.type}
                    placeholder={props.placeholder}
                    required={props.required}
                    className="custom-input-filed"
                    onChange={props.onChange}
                    name={props.name}
                    accept={props.accept}
                    sx={props.inlinecss}
                />
            </Box>
        </>
    );
};
export const CustomSelectForm = (props) => {
    return (
        <>
            <Select
                name={props.name}
                labelId={props.label}
                id={props.label}
                placeholder={props.placeholder}
                required={props.required}
                onChange={props.onChange}
                sx={{ width: "100%", height: "35px" }}
            >
                {props.names.map((name) => (
                    <MenuItem key={name} value={name}>
                        {name}
                    </MenuItem>
                ))}
            </Select>
        </>
    );
};
export const CustomTextArea = (props) => {
    return (
        <>
            <TextareaAutosize
                name={props.name}
                required={props.required}
                placeholder={props.placeholder}
                minRows={props.minRows}
                style={props.inlinecss}
                className="custom-textarea"
            />
        </>
    );
};
export const PhoneNumber = (props) => {
    return (
        <>
            <PhoneInput country={"in"} ClassName="custom-input-filed" name={props.InputName} required={props.required} />
        </>
    );
};
export const SectionContainer = (props) => {
    return (
        <>
            <Box className={"section " + props.SectionType} sx={props.inlinecss}>
                <Box className="section-title">
                    <Typography className="description">{props.SectionDesc}</Typography>
                    <Typography variant="h2">{props.SectionTitle}</Typography>
                </Box>
                {/* <Box mt={5}> */}
                {props.SectionBody}
                {/* </Box> */}
            </Box>
        </>
    );
};
