import Axios from 'axios';
import { API_URL } from '../../../App';

export const SetTeacher = async (data) => {
    console.log(data);
    try {
        const response = await Axios.post(`${API_URL}/teacher/add-teachers`, data);
        return response;
    } catch (err) {
        return err;
    }
}