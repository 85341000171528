import Axios from 'axios';
import { API_URL } from './../../../App';

export const getTeacher = async (TeacherId) => {
    try {
        const response = await Axios.post(`${API_URL}/teacher/teacher-detail`, {
            TeacherId: TeacherId,
        });
        return response;
    } catch (err) {
        return err;
    }
}
export const UpdateTeacher = async (details) => {
    try {
        const response = await Axios.post(`${API_URL}/teacher/edit-teacher`, details);
        return response;
    } catch (err) {
        return err;
    }
}