import Axios from 'axios';
import { API_URL } from '../../App';

export const setBookClass = async (data) => {
    try {
        const response = await Axios.post(`${API_URL}/my-class/book-class`, data);
        return response;
    } catch (err) {
        return err;
    }
}
export const GetScheduleClas = async () => {
    try {
        const response = await Axios.post(`${API_URL}/my-class/get-schedule-class`);
        return response;
    } catch (err) {
        return err;
    }
}