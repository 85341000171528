// import dayjs from 'dayjs';
import Axios from 'axios';
import { API_URL } from './../../App';

// const date = dayjs().format('YYYY-MM-DD HH:mm:ss');

export const setRegister = async (data) => {
    try {
        const response = await Axios.post(`${API_URL}/register`, data);
        return response;
    } catch (err) {
        return err;
    }

}