/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Card, Divider, Tabs, Tab, Typography } from "@mui/material";
import { Description, Heading } from "../../Components/BackendCommon";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import CheckIcon from "@mui/icons-material/Check";
import MemoryPower from "./../../Assets/Images/logo.png";
import { GetScheduleClas, setBookClass } from "./ApiCalls";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./BookClass.css";
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';

const BookClass = () => {

    const navigate = useNavigate();

    const [ScheduleClasData, setScheduleClasData] = useState([]);
    const [SelectedDate, setSelectedDate] = useState("");
    const [selectedTime, setSelectedTime] = useState("");
    const [SelectedTeacher, setSelectedTeacher] = useState("");
    const [selectedCourse, setSelectedCourse] = useState("");

    const CourseOption = ["Course 1", "Course 2", "Course 3"];

    useEffect(() => {
        GetScheduleClas()
            .then((response) => {
                setScheduleClasData(response.data)
                setSelectedDate("2023-04-22")
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const GetSelectDate = (event, newValue) => {
        setSelectedDate(newValue)
    };

    const ClassData = [];
    ScheduleClasData.map(row => {
        if (dayjs().isBefore(dayjs(row.class_date))) {
            if (ClassData.indexOf(row.class_date) === -1) {
                ClassData.push(row.class_date)
            }
        }
    });

    const BookClassFrom = () => {
        if (selectedCourse === "") {
            setSelectedCourse(null)
        } else if (SelectedDate === "") {
            setSelectedDate(null)
        } else if (selectedTime === "") {
            setSelectedTime(null)
        } else {
            const data = {
                course: selectedCourse,
                date: SelectedDate,
                time: selectedTime,
                teacher: SelectedTeacher,
            }
            setBookClass(data)
                .then((response) => {
                    if (response.status === 200) {
                        toast.success(response.data);
                        setTimeout(() => {
                            navigate('/')
                        }, 2000)
                    } else {
                        toast.error(response.data);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    return (
        <>
            <Breadcrumb PageName="Book Class" />
            <ToastContainer />
            <Card sx={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", mt: 3 }}>
                <Grid container sx={{ display: "flex" }}>
                    <Grid item xs={2}>
                        <Box px={5} py={4}>
                            <Grid container rowSpacing={2}>
                                <Grid item xs={12}>
                                    <img
                                        src={MemoryPower}
                                        style={{ height: "80px", width: "180px" }}
                                        alt="memory-power"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <Divider
                            orientation="vertical"
                            flexItem
                            style={{ width: "100%", height: "100%" }}
                        ></Divider>
                    </Grid>
                    <Grid item xs={9} className="book-class-from" p={3}>
                        <Box
                            sx={{
                                display: "flex",
                                maxHeight: 660,
                                flexDirection: "column",
                            }}
                        >
                            <Heading heading="Select Course" />
                            {selectedCourse === null ?
                                <Typography Typography variant="h5" className="error">Please Selct The Course *</Typography>
                                : null}
                            <Box>
                                {CourseOption.map((row, id) => {
                                    return (
                                        <Button
                                            key={id}
                                            sx={{ padding: "5px 10px" }}
                                            className={
                                                selectedCourse === row
                                                    ? "curose-btn active"
                                                    : "curose-btn"
                                            }
                                            onClick={() => setSelectedCourse(row)}
                                        >
                                            <Heading heading={row} color="#000" />
                                        </Button>
                                    )
                                })}
                            </Box>

                            <Heading heading="Select Date" />
                            {SelectedDate === null ?
                                <Typography Typography variant="h5" className="error">Please Selct The Date *</Typography>
                                : null}
                            <Tabs
                                variant="scrollable"
                                scrollButtons="auto"
                                className="date-wrapper"
                                value={SelectedDate}
                                onChange={GetSelectDate}
                            >
                                {ClassData.map((row, id) => {
                                    return (
                                        <Tab
                                            key={row}
                                            value={row}
                                            wrapped
                                            label={
                                                <Box sx={{
                                                    padding: "5px 10px",
                                                    display: 'inline-block',
                                                }}
                                                >
                                                    <Heading heading={dayjs(row).format('dddd')} color="#000" />
                                                    <Description description={dayjs(row).format('DD MMM')} />
                                                </Box>
                                            }
                                        />
                                    )
                                })}
                            </Tabs>
                            <Heading heading={"Select a time"} />
                            {selectedTime === null ?
                                <Typography Typography variant="h5" className="error">Please Selct The Time *</Typography>
                                : null}
                            <Box
                                sx={{
                                    p: 3,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                }}
                            >
                                <Grid container spacing={3}>
                                    {ScheduleClasData.map((row, id) => {
                                        return (
                                            <React.Fragment key={id}>
                                                {row.class_date === SelectedDate ?
                                                    <Grid item xs={3}>
                                                        <Button
                                                            variant="outlined"
                                                            className={
                                                                dayjs().isAfter(dayjs(row.class_date + " " + row.class_time)) ?
                                                                    "class-timing-btn disable" :
                                                                    selectedTime === row.class_time
                                                                        ? "class-timing-btn active"
                                                                        : "class-timing-btn"
                                                            }
                                                            startIcon={
                                                                selectedTime === row.class_time ? <CheckIcon /> : null
                                                            }
                                                            onClick={dayjs().isBefore(dayjs(row.class_date + " " + row.class_time)) ?
                                                                () => { setSelectedTime(row.class_time); setSelectedTeacher(row.teacher) }
                                                                : null}
                                                        >
                                                            {row.class_time}
                                                        </Button>
                                                    </Grid>
                                                    : null}
                                            </React.Fragment>
                                        );
                                    })}
                                </Grid>
                                <Box sx={{ textAlign: 'center', mt: 4 }}>
                                    <Button variant="contained" onClick={BookClassFrom}>Book Class</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid >
                </Grid >
            </Card >
        </>
    );
};

export default BookClass;