import Axios from 'axios';
import { API_URL } from './../../App';

export const getStudent = async (StudentId) => {
    try {
        const response = await Axios.post(`${API_URL}/students/student-detail`, {
            StudentId: StudentId,
        });
        return response;
    } catch (err) {
        return err;
    }
}
export const UpdateStudent = async (details) => {
    try {
        const response = await Axios.post(`${API_URL}/students/edit-student`, details);
        return response;
    } catch (err) {
        return err;
    }
}