import React, { useState } from 'react'
// import { useForm } from "react-hook-form";
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as yup from 'yup';
import Box from "@mui/material/Box";
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Breadcrumb from "../../../Components/Breadcrumb/Breadcrumb";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { Description, Heading } from "../../../Components/BackendCommon";
import { SetTeacher } from "./ApiCalls";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AddTeacher.css';

export default function AddTeacher() {

    const navigate = useNavigate();

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const names = [
        'Oliver Hansen',
        'Van Henry',
        'April Tucker',
        'Ralph Hubbard',
        'Omar Alexander',
        'Carlos Abbott',
        'Miriam Wagner',
        'Bradley Wilkerson',
        'Virginia Andrews',
        'Kelly Snyder',
    ];

    const [personName, setPersonName] = React.useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const gender = ["Male", "Female"];
    const languages = ["Hindi", "English", "Marathi"];
    const Courses = ["Courses 1", "Courses 2", "Courses 3"];

    // const schema = yup.object().shape({
    //     fullname: yup.string().required('Full Name Is A Required Field'),
    //     phone: yup.number().required('Phone Is A Required Field'),
    //     personal_email: yup.string().email().required('Personal Email Is A Required Field'),
    //     official_email: yup.string().email().required('Official Email Is A Required Field'),
    //     age: yup.string().required('Age Is A Required Field'),
    //     gender: yup.string().required('Gender Is A Required Field'),
    //     dob: yup.date().required('Date of Birth Is A Required Field'),
    //     languages: yup.string().required('Language Is A Required Field'),
    //     experience: yup.number().required('Years Of Experience Is A Required Field'),
    //     courses: yup.string().required('Courses Is A Required Field'),
    //     kyc: yup.string().required('Upload KYC Is A Required Field'),
    //     profile_img: yup
    //         .mixed()
    //         .test("required", "photo is required", value => value.length > 0)
    //         .test("fileSize", "File Size is too large", (value) => {
    //             return value.length && value[0].size <= 5242880;
    //         })
    //         .test("fileType", "Unsupported File Format", (value) => {
    //             return value.length && ["image/jpeg", "image/png", "image/jpg"].includes(value[0].type)
    //         }
    //         ),
    //     address: yup.string().required('Current Address Is A Required Field'),
    // })
    // const { register, handleSubmit, formState: { errors } } = useForm({
    //     resolver: yupResolver(schema),
    // });

    // =========== Add Teacher submit function ========
    const onSubmit = async (event) => {
        event.preventDefault();
        var data = new FormData(event.currentTarget);
        SetTeacher(data)
            .then((response) => {
                if (response.status === 200) {
                    event.target.reset();
                    toast.success(response.data);
                    setTimeout(() => {
                        navigate('/all-teacher')
                    }, 2000)
                } else {
                    toast.error(response.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const FormField = [
        {
            label: 'Teacher Name',
            fromtype: 'input',
            type: 'text',
            placeholder: 'Teacher Full Name :',
            options: '',
            name: "fullname",
        },
        {
            label: 'Phone Number',
            fromtype: 'input',
            type: 'number', placeholder: 'Phone Number :',
            options: '',
            name: "phone",
        },
        {
            label: 'Personal Email ID',
            fromtype: 'input', type: 'email',
            placeholder: 'Personal Email ID :',
            options: '',
            name: "personal_email",
        },
        {
            label: 'Official Email ID',
            fromtype: 'input', type: 'email',
            placeholder: 'Official Email ID :',
            options: '',
            name: "official_email",
        },
        {
            label: 'Teacher Age',
            fromtype: 'input',
            type: 'number',
            placeholder: 'Teacher Age :',
            options: '',
            name: "age",
        },
        {
            label: 'Teacher Gender',
            fromtype: 'select',
            type: '',
            placeholder: 'Select Teacher Gender',
            options: gender,
            name: "gender",
            //   onChange: ""
        },
        {
            label: 'Date of Birth',
            fromtype: 'input',
            type: 'date',
            placeholder: '',
            options: '',
            name: "dob",
        },
        {
            label: 'Language',
            fromtype: 'select',
            type: '',
            placeholder: 'Select Language',
            options: languages,
            name: "languages",
        },
        {
            label: 'Years of Experience',
            fromtype: 'input',
            type: 'number',
            placeholder: 'Years of Experience :',
            options: '',
            name: "experience",
        },
        {
            label: 'Courses',
            fromtype: 'multiselect',
            type: '',
            placeholder: 'Select Courses',
            options: Courses,
            name: "courses",
        },
        // {
        //     label: 'Upload KYC',
        //     fromtype: 'input',
        //     type: 'file',
        //     placeholder: '',
        //     options: '',
        //     name: "kyc_file",
        // },
        {
            label: 'Profile Picture',
            fromtype: 'input',
            type: 'file',
            placeholder: '',
            options: 'image',
            name: "profile_img",
            // onchange: { setimgfile }
        },
        {
            label: 'Current Address',
            fromtype: 'textarea',
            type: '',
            placeholder: 'Enter Current Address ...',
            options: '',
            name: "address",
        },
    ]

    return (
        <>
            <Breadcrumb PageName="Add Teachers" />
            <ToastContainer />
            <Box className="form-card" sx={{ mt: 3, mb: 1 }}>

                <Heading heading="Add Teacher" />

                <Divider sx={{ my: 1 }} />

                <Box component="form" autoComplete='off' onSubmit={onSubmit}>
                    <Grid container spacing={2} mt={1}>
                        {FormField.map((row, id) => {
                            return (
                                <React.Fragment key={id}>
                                    <Grid item md={2}>
                                        <Typography className="custom-form-label">{row.label} <small style={{ color: 'red' }}> *</small></Typography>
                                    </Grid>
                                    <Grid item md={4}>
                                        {row.fromtype === "input" ?
                                            <>
                                                {row.type === "file" ?
                                                    <>
                                                        <input type='file' name={row.name} required /><br/><br/>
                                                        <Description description="Note : Profile Images Size must be 1000X800" />
                                                    </>
                                                    : <input type={row.type} placeholder={row.placeholder} name={row.name} className="custom-input-filed" required />
                                                }
                                            </>
                                            : row.fromtype === 'select' ?
                                                <>
                                                    <Select
                                                        sx={{ width: "100%", height: "35px" }} name={row.name} required >
                                                        {row.options.map((name) => (
                                                            <MenuItem key={name} value={name}>
                                                                {name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </>
                                                : row.fromtype === 'multiselect' ?
                                                    <FormControl sx={{ m: 1, width: 300 }} className="custom-input-filed">
                                                        <InputLabel id="demo-multiple-checkbox-label">{row.label}</InputLabel>
                                                        <Select
                                                            labelId="demo-multiple-checkbox-label"
                                                            id="demo-multiple-checkbox"
                                                            multiple
                                                            name="courses"
                                                            value={personName}
                                                            onChange={handleChange}
                                                            required
                                                            input={<OutlinedInput label={row.label} />}
                                                            renderValue={(selected) => selected.join(', ')}
                                                            MenuProps={MenuProps}
                                                        >
                                                            {names.map((name) => (
                                                                <MenuItem key={name} value={name}>
                                                                    <Checkbox checked={personName.indexOf(name) > -1} />
                                                                    <ListItemText primary={name} />
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    : row.fromtype === 'textarea' ?
                                                        <TextareaAutosize placeholder={row.placeholder} minRows={3} name={row.name} className='custom-textarea' />
                                                        : null}
                                    </Grid>
                                </React.Fragment>
                            )
                        })}
                    </Grid>
                    <Box sx={{ textAlign: "center" }}>
                        <Button variant="contained" type="submit" sx={{ mt: 3 }}>Add Teacher</Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
}
