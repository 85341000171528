import * as React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import "./CountingCard.css";

const CountingCard = (props) => {
    return (
        <Card className="count-card" >
            <CardContent style={{ paddingBottom: "16px" }}>
                <Box className="count-card-head">
                    <AutorenewIcon sx={{background: props.BgColorCode}}/>
                    <span className="card-title">{props.SourceName}</span>
                </Box>
                <h4 className="counting">{props.LeadCount}</h4>
                <hr className="count-hr" style={{background: props.BgColorCode}}/>
            </CardContent>
        </Card>
    );
}
export default CountingCard