import React, { useEffect, useState } from 'react'
import Box from "@mui/material/Box";
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Heading } from "../../Components/BackendCommon";
import { getStudent, UpdateStudent } from "./ApiCalls";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './EditStudent.css';

export default function EditStudent() {

    const navigate = useNavigate();

    const [details, setDetails] = useState({
        student_id: "",
        source: "",
        student_name: "",
        contact_no: "",
        alt_contact_no: "",
        email_id: "",
        alt_email_id: "",
        garde: "",
        age: "",
        lead_status: "",
        follow_up: "",
        course_details: "",
        comments: "",
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDetails((prev) => {
            return { ...prev, [name]: value }
        })
    }

    const HandleFromSubmit = (e) => {
        e.preventDefault();
        UpdateStudent(details)
            .then((response) => {
                if (response.status === 200) {
                    toast.success(response.data);
                    setTimeout(() => {
                        navigate('/student')
                    }, 2000)
                } else {
                    toast.error(response.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    // ========= fetch teacher data ========
    const queryParameters = new URLSearchParams(window.location.search)
    const StudentId = queryParameters.get("student-id")

    useEffect(() => {
        getStudent(StudentId)
            .then((response) => {
                setDetails((prev) => {
                    return { ...response.data[0] }
                })
            })
            .catch((err) => {
                console.log(err);
            });
    }, [StudentId]);

    const SourceOptions = ["Google", "Facebook", "Reference"]
    const GardeOptions = ["Grade 1", "Grade 2", "Grade 3"]
    const AgeOptions = ["Age 1", "Age 2", "Age 3"]
    const FollowUpOptions = ["Yes", "No"]
    const LeadStatusOptions = ["New Lead", "Booked Free Trail", "Hot", "Not Intersted"]

    const FormField = [
        {
            label: 'Student ID',
            fromtype: 'input',
            type: 'hidden',
            placeholder: 'Student Full Name :',
            options: '',
            name: "student_id",
            value: details.student_id ? details.student_id : 'Undefined',
        },
        {
            label: 'Source',
            fromtype: 'select',
            type: '',
            placeholder: '',
            options: SourceOptions,
            name: "source",
            value: details.source ? details.source : 'Undefined',
        },
        {
            label: 'Student Name',
            fromtype: 'input',
            type: 'text',
            placeholder: 'Student Full Name :',
            options: '',
            name: "student_name",
            value: details.student_name ? details.student_name : 'Undefined',
        },
        {
            label: 'Contact No.',
            fromtype: 'input',
            type: 'number',
            placeholder: 'Phone Number :',
            options: '',
            name: "contact_no",
            value: details.contact_no ? details.contact_no : 'Undefined',
        },
        {
            label: 'Alt. Contact No.',
            fromtype: 'input',
            type: 'number',
            placeholder: 'Phone Number :',
            options: '',
            name: "alt_contact_no",
            value: details.alt_contact_no ? details.alt_contact_no : 'Undefined',
        },
        {
            label: 'Email ID',
            fromtype: 'input',
            type: 'email',
            placeholder: 'Email ID :',
            options: '',
            name: "email_id",
            value: details.email_id ? details.email_id : 'Undefined',
        },
        {
            label: 'Alt. Email ID',
            fromtype: 'input',
            type: 'email',
            placeholder: 'Alt. Email ID :',
            options: '',
            name: "alt_email_id",
            value: details.alt_email_id ? details.alt_email_id : 'Undefined',
        },
        {
            label: 'Garde',
            fromtype: 'select',
            type: '',
            placeholder: '',
            options: GardeOptions,
            name: "garde",
            value: details.garde ? details.garde : 'Undefined',
        },
        {
            label: 'Age',
            fromtype: 'select',
            type: '',
            placeholder: '',
            options: AgeOptions,
            name: "age",
            value: details.age ? details.age : 'Undefined',
        },
        {
            label: 'Lead Status',
            fromtype: 'select',
            type: '',
            placeholder: '',
            options: LeadStatusOptions,
            name: "lead_status",
            value: details.lead_status ? details.lead_status : 'Undefined',
        },
        {
            label: 'Follow Up',
            fromtype: 'select',
            type: '',
            placeholder: '',
            options: FollowUpOptions,
            name: "follow_up",
            value: details.follow_up ? details.follow_up : 'Undefined',
        },
        {
            label: 'Course Details',
            fromtype: 'select',
            type: '',
            placeholder: '',
            options: FollowUpOptions,
            name: "course_details",
            value: details.course_details ? details.course_details : 'Undefined',
        },
        {
            label: 'Comments',
            fromtype: 'textarea',
            type: '',
            placeholder: 'Enter Comments ...',
            options: '',
            name: "comments",
            value: details.comments ? details.comments : 'Undefined',
        },
    ]

    return (
        <>
            <Breadcrumb PageName="Add Student" />
            <ToastContainer />
            <Box className="form-card" sx={{ mt: 3, mb: 1 }}>

                <Heading heading="Add Student" />

                <Divider sx={{ my: 1 }} />

                <Box component="form" autoComplete='off' onSubmit={HandleFromSubmit}>
                    <Grid container spacing={2} mt={1}>
                        {FormField.map((row, id) => {
                            return (
                                <React.Fragment key={id}>
                                    <Grid item md={2} sx={{ display: row.label === "Student ID" ? 'none' : null }}>
                                        <Typography className="custom-form-label">{row.label} <small style={{ color: 'red' }}> *</small></Typography>
                                    </Grid>
                                    <Grid item md={4} sx={{ display: row.label === "Student ID" ? 'none' : null }}>
                                        {row.fromtype === "input" ?
                                            <input
                                                type={row.type}
                                                placeholder={row.placeholder}
                                                name={row.name}
                                                className="custom-input-filed"
                                                required
                                                value={row.value}
                                                onChange={handleInputChange}
                                            />
                                            : row.fromtype === 'select' ?
                                                <>
                                                    <Select
                                                        sx={{ width: "100%", height: "35px" }}
                                                        name={row.name}
                                                        required
                                                        onChange={handleInputChange}
                                                        value={row.value}
                                                    >
                                                        {row.options.map((name) => (
                                                            <MenuItem key={name} value={name}>
                                                                {name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </>
                                                : row.fromtype === 'textarea' ?
                                                    <TextareaAutosize
                                                        placeholder={row.placeholder}
                                                        minRows={3}
                                                        name={row.name}
                                                        className='custom-textarea'
                                                        value={row.value}
                                                        onChange={handleInputChange}
                                                    />
                                                    : null}
                                    </Grid>
                                </React.Fragment>
                            )
                        })}
                    </Grid>
                    <Box sx={{ textAlign: "center" }}>
                        <Button variant="contained" type="submit" sx={{ mt: 3 }}>Add Student</Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
}
