import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import CustomDatatable from "../../Helpers/CustomDatatable/CustomDatatable";
import CustomModal from "../../Helpers/CustomModal/CustomModal";
import dayjs from 'dayjs';
import { getAllBookedClasses, getDeleteBookedClasses } from './ApiCalls';
import "./BookedClasses.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { API_URL } from './../../App';

const BookedClasses = () => {

    const navigate = useNavigate();

    const [bookedclasses, setAllBookedClasses] = React.useState([]);

    const [BookedClassesId, setBookedClassesId] = React.useState();
    const [open, setOpen] = React.useState(false);

    const OpenDeleteModal = (BookedClassesId) => {
        setOpen(true)
        setBookedClassesId(BookedClassesId);
    }
    const CloseDeleteModal = () => {
        setOpen(false)
        setBookedClassesId(null);
    }

    const GetDatatableData = () => {
        getAllBookedClasses().then(response => {
            if (response.data === "No Data Found") {
                setAllBookedClasses(response.data.data)
            } else {
                setAllBookedClasses(response.data)
            }
        }).catch(err => {
            console.log(err);
        });
    }

    const DeleteBookedClasses = () => {
        getDeleteBookedClasses(BookedClassesId)
            .then((response) => {
                if (response.status === 200) {
                    toast.success(response.data);
                    CloseDeleteModal();
                    GetDatatableData();
                } else {
                    toast.error(response.data)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    React.useEffect(() => {
        GetDatatableData();
    }, []);

    const columns = [
        {
            name: "id",
            label: "Id",
            options: {
                display: false,
            },
        },
        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <Box sx={{ display: "flex" }}>
                            <IconButton
                                color="error"
                                aria-label="delete"
                                component="label"
                                onClick={() => {
                                    OpenDeleteModal(bookedclasses[dataIndex].booked_class_id);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    );
                },
            },
        },
        {
            name: "posted_dt",
            label: "Posted On",
        },
        {
            name: "course",
            label: "Course",
        },
        {
            name: "teacher",
            label: "Teacher Name",
        },

        {
            name: "booked_by",
            label: "Student Name",
        },
        {
            name: "class_date",
            label: "class Date",
        },
        {
            name: "class_time",
            label: "class Time",
        },
    ];

    return (
        <>
            <Breadcrumb PageName="Booked Classes" />
            <ToastContainer />
            <Box mt={3} className='booked-classes-section'>
                <CustomModal
                    open={open}
                    onClose={CloseDeleteModal}
                    DeleteBtn={DeleteBookedClasses}
                />
                <CustomDatatable
                    title="All Booked Classes"
                    noMatch="No Booked Classes Found"
                    columns={columns}
                    data={bookedclasses?.map(row => {
                        return [
                            row.booked_class_id,
                            row,
                            dayjs().format('YYYY-MM-DD') === dayjs(row.posted_dt).format('YYYY-MM-DD') ? 'Today at ' + dayjs(row.posted_dt).format('hh:mm a') : 'Yesterday at ' + dayjs(row.posted_dt).format('hh:MM a'),
                            row.course,
                            row.teacher,
                            row.booked_by,
                            row.class_date,
                            row.class_time,
                        ]
                    })}
                />
            </Box>
        </>
    );
};

export default BookedClasses