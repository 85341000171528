// import dayjs from 'dayjs';
import Axios from 'axios';
import { API_URL } from './../../App';

export const getAllBookedClasses = async () => {
    try {
        const response = await Axios.post(`${API_URL}/my-class/get-all-booked-class`);
        return response;

    } catch (err) {

        return err;

    }
}
export const getDeleteBookedClasses = async (BookedClassesId) => {
    try {
        const response = await Axios.post(`${API_URL}/my-class/delete-booked-class`, {
            booked_class_id: BookedClassesId,
        });
        return response;

    } catch (err) {

        return err;

    }
}