import React, { useState } from "react";
import dayjs from 'dayjs';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import CustomDatatable from "../../Helpers/CustomDatatable/CustomDatatable";
import CustomModal from "../../Helpers/CustomModal/CustomModal";
import { getAllStudent, getDeleteStudent } from "./ApiCalls";
import { useNavigate } from "react-router-dom";
import "./Student.css";

const Student = () => {

    const navigate = useNavigate();

    const [student, setAllLead] = React.useState([]);

    const [student_id, setstudent_id] = useState();
    const [open, setOpen] = useState(false);

    const OpenDeleteModal = (student_id) => {
        setOpen(true)
        setstudent_id(student_id);
    }
    const CloseDeleteModal = () => {
        setOpen(false)
        setstudent_id(null);
    }

    const GetDatatableData = () => {
        getAllStudent().then(response => {
            console.log(response);
            if (response.data === "No Data Found") {
                setAllLead(response.data.data)
            } else {
                setAllLead(response.data)
            }
        }).catch(err => {
            console.log(err);
        });
    }

    const DeleteLead = () => {
        getDeleteStudent(student_id)
            .then((response) => {
                console.log(response)
                if (response.status === 200) {
                    CloseDeleteModal();
                    GetDatatableData();
                } else {
                    alert(response.data)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    React.useEffect(() => {
        GetDatatableData();
    }, []);

    const columns = [
        {
            name: "id",
            label: "Id",
            options: {
                display: false,
            },
        },
        {
            name: "created_dt",
            label: "Posted On",
        },
        {
            name: "name",
            label: "Student Name",
        },
        {
            name: "lead_status",
            label: "Lead Status",
        },
        {
            name: "mobile",
            label: "Mobile",
        },
        {
            name: "email",
            label: "Email",
        },
        {
            name: "age",
            label: "Age",
        },
        {
            name: "garde",
            label: "Garde",
        },
        {
            name: "course_details",
            label: "Course Details",
        },
        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <Box sx={{ display: "flex" }}>
                            <IconButton
                                color="primary"
                                aria-label="edit"
                                component="label"
                                onClick={() => {
                                    navigate('/edit-student?student-id=' + student[dataIndex].student_id)
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton
                                color="error"
                                aria-label="delete"
                                component="label"
                                onClick={() => {
                                    OpenDeleteModal(student[dataIndex].student_id);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    );
                },
            },
        },
    ];

    return (
        <>
            <Breadcrumb PageName="Student" btnName="Add Student" BtnRedirect="/add-student" />
            <Box mt={3}>
                <CustomModal
                    open={open}
                    onClose={CloseDeleteModal}
                    DeleteBtn={DeleteLead}
                />
                <CustomDatatable
                    title="All Student's"
                    noMatch="No Lead Found"
                    columns={columns}
                    data={student?.map(row => {
                        return [
                            row.student_id,
                            dayjs().format('YYYY-MM-DD') === dayjs(row.created_dt).format('YYYY-MM-DD') ? 'Today at ' + dayjs(row.created_dt).format('hh:mm a') : 'Yesterday at ' + dayjs(row.created_dt).format('hh:MM a'),
                            row.student_name,
                            row.lead_status,
                            row.contact_no,
                            row.email_id,
                            row.age,
                            row.garde,
                            row.course_details,
                        ]
                    })}
                />
            </Box>
        </>
    )
}

export default Student