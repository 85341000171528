import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Breadcrumb from "../../../Components/Breadcrumb/Breadcrumb";
import CustomDatatable from "../../../Helpers/CustomDatatable/CustomDatatable";
import CustomModal from "../../../Helpers/CustomModal/CustomModal";
import dayjs from 'dayjs';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { getTeachers, getDeleteTeacher } from './ApiCalls';
import "./AllTeacher.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { API_URL } from './../../../App';

const AllTeacher = () => {

    const navigate = useNavigate();

    const [teacher, setAllTeacher] = React.useState([]);

    const [TeacherId, setTeacherId] = React.useState();
    const [open, setOpen] = React.useState(false);

    const OpenDeleteModal = (TeacherId) => {
        setOpen(true)
        setTeacherId(TeacherId);
    }
    const CloseDeleteModal = () => {
        setOpen(false)
        setTeacherId(null);
    }

    const GetDatatableData = () => {
        getTeachers().then(response => {
            if (response.data === "No Data Found") {
                setAllTeacher(response.data.data)
            } else {
                setAllTeacher(response.data)
            }
        }).catch(err => {
            console.log(err);
        });
    }

    const DeleteTeacher = () => {
        getDeleteTeacher(TeacherId)
            .then((response) => {
                if (response.status === 200) {
                    toast.success(response.data);
                    CloseDeleteModal();
                    GetDatatableData();
                } else {
                    toast.error(response.data)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    React.useEffect(() => {
        GetDatatableData();
    }, []);

    const columns = [
        {
            name: "id",
            label: "Id",
            options: {
                display: false,
            },
        },
        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <Box sx={{ display: "flex" }}>
                            <IconButton
                                color="primary"
                                aria-label="edit"
                                component="label"
                                onClick={() => {
                                    navigate('/edit-teacher?teacher-id=' + teacher[dataIndex].teacher_id)
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton
                                color="error"
                                aria-label="delete"
                                component="label"
                                onClick={() => {
                                    OpenDeleteModal(teacher[dataIndex].teacher_id);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    );
                },
            },
        },
        {
            name: "created_dt",
            label: "Posted On",
        },
        {
            name: "full_name",
            label: "Full Name",
        },
        {
            name: "contact_no",
            label: "Contact No.",
        },
        {
            name: "personal_email_id",
            label: "Personal Email ID",
        },

        {
            name: "official_email_id",
            label: "Official Email ID ",
        },
        {
            name: "age",
            label: "Age",
        },
        {
            name: "gender",
            label: "Gender",
        },
        {
            name: "dob",
            label: "Date of Birth",
        },
        {
            name: "language",
            label: "Language",
        },
        {
            name: "years_of_exp",
            label: "Years of Experience",
        },
        {
            name: "courses",
            label: "Courses",
        },
        {
            name: "current_address",
            label: "Current Address",
        },
        // {
        //     name: "profile_picture",
        //     label: "Profile Picture",
        // },
    ];
 
    return (
        <>
            <Breadcrumb PageName="Teachers" btnName="Add Teacher" BtnRedirect="/add-teacher" />
            <ToastContainer />
            <Box mt={3} className='teacher-section'>
                <CustomModal
                    open={open}
                    onClose={CloseDeleteModal}
                    DeleteBtn={DeleteTeacher}
                />
                <CustomDatatable
                    title="All Teacher's"
                    noMatch="No Teacher Found"
                    columns={columns}
                    data={teacher?.map(row => {
                        return [
                            row.teacher_id,
                            row,
                            dayjs().format('YYYY-MM-DD') === dayjs(row.created_dt).format('YYYY-MM-DD') ? 'Today at ' + dayjs(row.created_dt).format('hh:mm a') : 'Yesterday at ' + dayjs(row.created_dt).format('hh:MM a'),
                            <>
                                <ListItem alignItems="center">
                                    <ListItemAvatar>
                                        {console.log(`${API_URL}/Upload/Teacher/${row.profile_img}`)}
                                        <Avatar alt={row.fullname} src={`${API_URL}/Upload/Teacher/${row.profile_img}`} />
                                        {/* <Avatar alt={row.fullname} src={"./../../../../backend/Routes/Images/" + row.profile_img} /> */}
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={row.fullname}
                                    />
                                </ListItem>
                            </>,
                            row.phone,
                            row.personal_email_id,
                            row.official_email_id,
                            row.age,
                            row.gender,
                            row.date_of_birth,
                            row.language,
                            row.yrs_of_exp,
                            row.courses,
                            row.current_address,
                        ]
                    })}
                />
            </Box>
        </>
    );
};

export default AllTeacher;
