import Axios from 'axios';
import { API_URL } from './../../App';

export const getAllScheduleClass = async () => {
    try {
        const response = await Axios.post(`${API_URL}/schedule-class/get-all-schedule-class`);
        return response;

    } catch (err) {

        return err;

    }
}
export const getDeleteScheduleClass = async (ScheduleClassId) => {
    try {
        const response = await Axios.post(`${API_URL}/schedule-class/delete-schedule-class`, {
            schedule_class_id:ScheduleClassId
        });
        return response;

    } catch (err) {

        return err;

    }
}