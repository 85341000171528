// import dayjs from 'dayjs';
import Axios from 'axios';
import { API_URL } from './../../../App';

// export const setTeacher = async (data) => {
//     try {
//         const response = await Axios.post(`${API_URL}/teacher/add-teacher`, {
//             first_name: data.get('teacher_first_name'),
//             last_name: data.get('teacher_last_name'),
//             phone: data.get('teacher_phone'),
//             personal_email: data.get('teacher_personal_email'),
//             official_email: data.get('teacher_official_email'),
//             age: data.get('age'),
//             gender: data.get('gender'),
//             dob: data.get('date_of_birth'),
//             language: data.get('language'),
//             yrs_of_exp: data.get('years_of_experience'),
//             kyc: data.get('kyc'),
//             address: data.get('address'),
//             password: data.get('teacher_password'),
//         });

//         return response;

//     } catch (err) {

//         return err;

//     }
// }

export const getTeachers = async () => {
    try {
        const response = await Axios.post(`${API_URL}/teacher/get-teachers`);
        return response;

    } catch (err) {

        return err;

    }
}
export const getDeleteTeacher = async (TeacherId) => {
    try {
        const response = await Axios.post(`${API_URL}/teacher/delete-teachers`, {
            teacher_id: TeacherId,
        });
        return response;

    } catch (err) {

        return err;

    }
}