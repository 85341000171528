import Axios from 'axios';
import { API_URL } from '../../App';

export const SetStudent = async (data) => {
    try {
        const response = await Axios.post(`${API_URL}/students/add-student`, {
            source: data.get('source'),
            student_name: data.get('student_name'),
            contact_no: data.get('contact_no'),
            alt_contact_no: data.get('alt_contact_no'),
            email_id: data.get('email_id'),
            alt_email_id: data.get('alt_email_id'),
            garde: data.get('garde'),
            age: data.get('age'),
            lead_status: data.get('lead_status'),
            follow_up: data.get('follow_up'),
            course_details: data.get('course_details'),
            comments: data.get('comments'),
        });
        return response;
    } catch (err) {
        return err;
    }
}