import React, { useEffect, useState } from 'react'
// import { useForm } from "react-hook-form";
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as yup from 'yup';
import Box from "@mui/material/Box";
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Breadcrumb from "../../../Components/Breadcrumb/Breadcrumb";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { Description, Heading } from "../../../Components/BackendCommon";
import { getTeacher, UpdateTeacher } from "./ApiCalls";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import './EditTeacher.css';

export default function EditTeacher() {
    const navigate = useNavigate();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const names = [
        'Oliver Hansen',
        'Van Henry',
        'April Tucker',
        'Ralph Hubbard',
        'Omar Alexander',
        'Carlos Abbott',
        'Miriam Wagner',
        'Bradley Wilkerson',
        'Virginia Andrews',
        'Kelly Snyder',
    ];

    const [personName, setPersonName] = React.useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    // ============ Form Working ==========
    const [details, setDetails] = useState({
        teacher_id: "",
        fullname: "",
        phone: "",
        personal_email_id: "",
        official_email_id: "",
        age: "",
        gender: "",
        date_of_birth: "",
        language: "",
        yrs_of_exp: "",
        current_address: "",
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDetails((prev) => {
            return { ...prev, [name]: value }
        })
    }

    const HandleFromSubmit = (e) => {
        e.preventDefault();
        UpdateTeacher(details)
            .then((response) => {
                if (response.status === 200) {
                    toast.success(response.data);
                    setTimeout(() => {
                        navigate('/all-teacher')
                    }, 2000)
                } else {
                    toast.error(response.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    // ========= fetch teacher data ========
    const queryParameters = new URLSearchParams(window.location.search)
    const TeacherId = queryParameters.get("teacher-id")

    useEffect(() => {
        getTeacher(TeacherId)
            .then((response) => {
                setDetails((prev) => {
                    return { ...response.data[0] }
                })
            })
            .catch((err) => {
                console.log(err);
            });
    }, [TeacherId]);



    // const navigate = useNavigate();



    const gender = ["Male", "Female"];
    const language = ["Hindi", "English", "Marathi"];
    const Courses = ["Courses 1", "Courses 2", "Courses 3"];

    const FormField = [
        {
            label: 'Teacher ID',
            fromtype: 'input',
            type: 'hidden',
            placeholder: 'Teacher Full Name :',
            options: '',
            name: "teacher_id",
            value: details.teacher_id ? details.teacher_id : 'Undefined',
        },
        {
            label: 'Teacher Name',
            fromtype: 'input',
            type: 'text',
            placeholder: 'Teacher Full Name :',
            options: '',
            name: "fullname",
            value: details.fullname ? details.fullname : 'Undefined',
        },
        {
            label: 'Phone Number',
            fromtype: 'input',
            type: 'number', placeholder: 'Phone Number :',
            options: '',
            name: "phone",
            value: details.phone ? details.phone : 'Undefined',
        },
        {
            label: 'Personal Email ID',
            fromtype: 'input', type: 'email',
            placeholder: 'Personal Email ID :',
            options: '',
            name: "personal_email_id",
            value: details.personal_email_id ? details.personal_email_id : 'Undefined',
        },
        {
            label: 'Official Email ID',
            fromtype: 'input', type: 'email',
            placeholder: 'Official Email ID :',
            options: '',
            name: "official_email_id",
            value: details.official_email_id ? details.official_email_id : 'Undefined',
        },
        {
            label: 'Teacher Age',
            fromtype: 'input',
            type: 'number',
            placeholder: 'Teacher Age :',
            options: '',
            name: "age",
            value: details.age ? details.age : 'Undefined',
        },
        {
            label: 'Teacher Gender',
            fromtype: 'select',
            type: '',
            placeholder: 'Select Teacher Gender',
            options: gender,
            name: "gender",
            value: details.gender ? details.gender : "Undefined",
            //   onChange: ""
        },
        {
            label: 'Date of Birth',
            fromtype: 'input',
            type: 'date',
            placeholder: '',
            options: '',
            name: "date_of_birth",
            value: details.date_of_birth ? details.date_of_birth : 'Undefined',
        },
        {
            label: 'Language',
            fromtype: 'select',
            type: '',
            placeholder: 'Select Language',
            options: language,
            name: "language",
            value: details.language ? details.language : "Undefined",
        },
        {
            label: 'Years of Experience',
            fromtype: 'input',
            type: 'number',
            placeholder: 'Years of Experience :',
            options: '',
            name: "yrs_of_exp",
            value: details.yrs_of_exp ? details.yrs_of_exp : 'Undefined',
        },
        {
            label: 'Courses',
            fromtype: 'multiselect',
            type: '',
            placeholder: 'Select Courses',
            options: Courses,
            name: "courses",
            value: "",
        },
        // {
        //     label: 'Profile Picture',
        //     fromtype: 'input',
        //     type: 'file',
        //     placeholder: '',
        //     options: 'image',
        //     name: "profile_img",
        //     value: TeacherDetails.fullname,
        //     // onchange: { setimgfile }
        // },
        {
            label: 'Current Address',
            fromtype: 'textarea',
            type: '',
            placeholder: 'Enter Current Address ...',
            options: '',
            name: "current_address",
            value: details.current_address ? details.current_address : 'Undefined',
        },
    ]

    return (
        <>
            <Breadcrumb PageName="Edit Teachers" />
            <ToastContainer />
            <Box className="form-card" sx={{ mt: 3, mb: 1 }}>

                <Heading heading="Edit Teacher" />

                <Divider sx={{ my: 1 }} />

                <Box component="form" autoComplete='off' onSubmit={HandleFromSubmit}>
                    <Grid container spacing={2} mt={1}>
                        {FormField.map((row, id) => {
                            return (
                                <React.Fragment key={id}>
                                    <Grid item md={2} sx={{ display: row.label === "Teacher ID" ? 'none' : null }}>
                                        <Typography className="custom-form-label">{row.label} <small style={{ color: 'red' }}> *</small></Typography>
                                    </Grid>
                                    <Grid item md={4} sx={{ display: row.label === "Teacher ID" ? 'none' : null }}>
                                        {row.fromtype === "input" ?
                                            <>
                                                {row.type === "file" ?
                                                    <>
                                                        <input type='file' name={row.name} required />
                                                        <Description description="Note : Featured Image Size must be 1000X800" />
                                                    </>
                                                    : <input
                                                        type={row.type}
                                                        placeholder={row.placeholder}
                                                        name={row.name}
                                                        value={row.value}
                                                        className="custom-input-filed"
                                                        required
                                                        onChange={handleInputChange}
                                                    />
                                                }
                                            </>
                                            : row.fromtype === 'select' ?
                                                <>
                                                    <Select
                                                        sx={{ width: "100%", height: "35px" }}
                                                        name={row.name}
                                                        value={row.value}
                                                        required
                                                        onChange={handleInputChange}
                                                    >
                                                        {row.options.map((name) => (
                                                            <MenuItem key={name} value={name}>
                                                                {name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </>
                                                : row.fromtype === 'multiselect' ?
                                                    <FormControl sx={{ m: 1, width: 300 }} className="custom-input-filed">
                                                        <InputLabel id="demo-multiple-checkbox-label">{row.label}</InputLabel>
                                                        <Select
                                                            labelId="demo-multiple-checkbox-label"
                                                            id="demo-multiple-checkbox"
                                                            multiple
                                                            name="courses"
                                                            value={personName}
                                                            onChange={handleChange}
                                                            required
                                                            input={<OutlinedInput label={row.label} />}
                                                            renderValue={(selected) => selected.join(',')}
                                                            MenuProps={MenuProps}
                                                        >
                                                            {names.map((name) => (
                                                                <MenuItem key={name} value={name}>
                                                                    <Checkbox checked={personName.indexOf(name) > -1} />
                                                                    <ListItemText primary={name} />
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    : row.fromtype === 'textarea' ?
                                                        <TextareaAutosize placeholder={row.placeholder} minRows={3} name={row.name} className='custom-textarea' value={row.value} onChange={handleInputChange} />
                                                        : null}
                                    </Grid>
                                </React.Fragment>
                            )
                        })}
                    </Grid>
                    <Box sx={{ textAlign: "center" }}>
                        <Button variant="contained" type="submit" sx={{ mt: 3 }}>Edit Teacher</Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
}
