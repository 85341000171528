import React, { useState, useEffect } from "react";
import Headerlogo from "./../../Assets/Images/logo.png";
import PsychologyIcon from '@mui/icons-material/Psychology';
import OTPInput from "otp-input-react";
import Box from "@mui/material/Box";
import { Description, Heading } from "./../../Components/BackendCommon";
import Button from '@mui/material/Button';
import "./Register.css";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import Typography from "@mui/material/Typography";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setRegister } from './ApiCalls';
import Axios from 'axios';
import { useNavigate } from "react-router-dom";

const Register = () => {

    const navigate = useNavigate();

    const [code, setCode] = useState(0);
    const [number, setNumber] = useState(0);
    const [fullname, setFullname] = useState("");
    const [OTP, setOTP] = useState(0);
    const [UserOTP, setUserOTP] = useState("");
    const [minutes, setMinutes] = useState(3);
    const [seconds, setSeconds] = useState(0);

    const SendOTP = async () => {
        let domain = window.location.href;
        var MinOtp = 100000;
        var MaxOtp = 999999;
        const otp = Math.floor(Math.random() * (MaxOtp - MinOtp + 1)) + MinOtp;
        const varUserName = 't1rabsnetsolution';
        const varPWD = 'bilal123';
        const varSenderID = 'BLALsh';
        const varEntityID = '1701162426418560936';
        const varTemplateID = '1707165432561057137';

        const varMSG = 'Dear%20' + fullname + '%20Your%20www.google.com%20OTP%20is%20' + otp + '%20You%20must%20be%20verify%20your%20contact%20number%20for%20www.google.com%20Thank%20you%20BLALsh';
        const data = "username=" + varUserName + "&password=" + varPWD + "&sender=" + varSenderID + "&sendto=" + code + number + "&entityID=" + varEntityID + "&templateID=" + varTemplateID + "&message=" + varMSG;
        // https://sms4power.com/api/swsendSingle.asp?username=t1rabsnetsolution&password=bilal123&sender=BLALsh&sendto=919930165607&entityID=1701162426418560936&templateID=1707165432561057137&message=Dear%20Punit%20Your%20www.google.com%20OTP%20is%20785866%20You%20must%20be%20verify%20your%20contact%20number%20for%20www.google.com%20Thank%20you%20BLALsh
        setOTP(otp);
        try {
            const response = await Axios.post("https://sms4power.com/api/swsendSingle.asp", data);
            toast.success("OTP send successfully");
            return response;
        } catch (err) {
            return err;
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [minutes, seconds]);

    const ResendOTP = () => {
        SendOTP();
        setMinutes(3);
        setSeconds(0);
    }

    const GetUserDetails = (event) => {
        event.preventDefault();
        SendOTP();
    }

    const VerifyUser = async (event) => {
        event.preventDefault();
        const verfyotp = "" + UserOTP;
        // eslint-disable-next-line eqeqeq
        if (OTP == verfyotp) {
            const data = {
                fullname: fullname,
                phone: number,
            }
            setRegister(data)
                .then((response) => {
                    if (response.status === 200) {
                        toast.success(response.data);
                        setTimeout(() => {
                            navigate('/book-class')
                        }, 2000)
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            toast.error('Wrong OTP');
        }
    }

    return (
        <>
            <ToastContainer />
            <Box className="register-wrapper area">
                <ul className="circles">
                    <li><PsychologyIcon /></li>
                    <li><PsychologyIcon /></li>
                    <li><PsychologyIcon /></li>
                    <li><PsychologyIcon /></li>
                    <li><PsychologyIcon /></li>
                    <li><PsychologyIcon /></li>
                    <li><PsychologyIcon /></li>
                    <li><PsychologyIcon /></li>
                    <li><PsychologyIcon /></li>
                    <li><PsychologyIcon /></li>
                </ul>
                <Box className="register-card">
                    <img src={Headerlogo} width={"150"} alt="Logo" />
                    <Heading heading="Book Your Free Class" />
                    <Box component="form" autoComplete="off" onSubmit={OTP === 0 ? GetUserDetails : VerifyUser}>
                        <Box mt={3} mb={OTP === 0 ? 3 : null}>
                            {OTP === 0 ?
                                <>
                                    <Box>
                                        <Typography className="custom-form-label">Full Name</Typography>
                                        <input
                                            style={{ marginBottom: '10px' }}
                                            type="text"
                                            placeholder="Enter Your Fullname"
                                            name="fullname"
                                            className="custom-input-filed"
                                            required
                                            onChange={(e) => { setFullname(e.target.value) }}
                                        />
                                    </Box>
                                    <Box sx={{ mt: 1 }}>
                                        <Typography className="custom-form-label">Contact No.</Typography>
                                        <PhoneInput
                                            country={"in"}
                                            containerClass="form-control"
                                            required
                                            onChange={(value, data, event, formattedValue) =>
                                                setCode(data.dialCode) + setNumber(value.slice(data.dialCode.length))
                                            }
                                        />
                                    </Box>
                                </> :
                                <Box className="otp-wapper">
                                    <span className="resend-btn" onClick={() => { setOTP(0); setUserOTP("") }}>Change Number</span>
                                    <Description description="Enter the OTP you received" />
                                    <OTPInput
                                        value={UserOTP}
                                        onChange={setUserOTP}
                                        autoFocus={true}
                                        OTPLength={6}
                                        otpType="number"
                                    />
                                    <Box className="countdown-text" mt={2}>
                                        {seconds > 0 || minutes > 0 ? (
                                            <>
                                                <Typography className="description">
                                                    You should receive the OTP in &nbsp;
                                                    <span style={{ color: '#e74c3c', fontWeight: '500' }}>
                                                        {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds} minutes
                                                    </span>
                                                </Typography>
                                            </>
                                        ) : (
                                            <Typography className="description">Didn't recieve code ? <span onClick={ResendOTP} className="resend-btn">Resend</span></Typography>
                                        )}

                                    </Box>
                                </Box>
                            }
                        </Box>
                        <Description description="By clicking the 'Schedule a FREE Class' button you agree to Whitehat Jr Terms & Conditions." />
                        <Button variant="contained" type="submit" sx={{ margin: '15px 0px' }}>{OTP === 0 ? "Register" : "Verify your phone number"}</Button>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Description description="Already have account ? " />
                        {/* <Link className="register-link" to="/login">&nbsp;Login</Link> */}
                    </Box>
                </Box>
            </Box >
        </>
    );
};
export default Register;
