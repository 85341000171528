import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AllScheduleClass, Dashboard, ScheduleClass, EditScheduleClass, Course, Instructors, Profile, Student, AddStudent, EditStudent, AllTeacher, AddTeacher, EditTeacher, Register, BookClass, BookedClasses } from './Pages/Pages';
import LeftSideNav from './Components/LeftSideNav/LeftSideNav';
import axios from 'axios';
import './App.css';

// export const API_URL = "http://localhost:3001";
export const API_URL = "https://memorypowerbackenddb.rabs.support"
axios.defaults.withCredentials = true;

const App = () => {
  return (
    <>

      <Router>
        <Routes>
          <Route path="/" element={<LeftSideNav />} >
            <Route path="/" element={<Dashboard />} />
            <Route path="/schedule-class" element={<ScheduleClass />} />
            <Route path="/all-schedule-class" element={<AllScheduleClass />} />
            <Route path="/edit-schedule-class" element={<EditScheduleClass />} />
            <Route path="/course" element={<Course />} />
            <Route path="/instructors" element={<Instructors />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/student" element={<Student />} />
            <Route path="/add-student" element={<AddStudent />} />
            <Route path="/edit-student" element={<EditStudent />} />
            <Route path="/all-teacher" element={<AllTeacher />} />
            <Route path="/add-teacher" element={<AddTeacher />} />
            <Route path="/edit-teacher" element={<EditTeacher />} />
            <Route path="/book-class" element={<BookClass />} />
            <Route path="/booked-classes" element={<BookedClasses />} />
          </Route>
          <Route path="/register" element={<Register />} />
        </Routes>
      </Router >
    </>
  )
}

export default App
